import React, { memo, useCallback, useMemo } from "react";
import {
  TableCell,
  TableRow,
  makeStyles,
  colors,
  Theme,
  IconButton,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { CheckoutPage } from "../types";
import { TestProfilesFieldProps } from "../AddCheckoutPageDialog/TestProfilesField";

const TABLE_ROW_COLOR = "#f7fafc";

interface StyleProps {
  isOdd: boolean;
  BloodDrawFeeCollected: boolean;
}

const useStyle = makeStyles<Theme, StyleProps>(
  ({ palette: p, spacing: s }) => ({
    row: ({ isOdd }) => ({
      background: isOdd ? undefined : TABLE_ROW_COLOR,
    }),
    feeCollected: {
      color: ({ BloodDrawFeeCollected }) =>
        BloodDrawFeeCollected ? colors.green[500] : colors.red[500],
    },
    tableContainer: {
      flex: 1,
      padding: s(4),
    },
    text: {
      color: p.text.primary,
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    sicIcon: {
      transformOrigin: "center",
      transition: "all 0.1s",
    },
  })
);

interface BodyRowProps {
  checkOutPage: CheckoutPage;
  isOdd: boolean;
  testProfiles: TestProfilesFieldProps["testProfiles"];
  handleChangeCheckoutPage: (
    checkoutPage: CheckoutPage,
    action: "delete" | "edit"
  ) => void;
}

export const BodyRow: React.FC<BodyRowProps> = ({
  checkOutPage: {
    Name,
    BloodTestingLab,
    "MasterZohoCheckoutPageTestProfile List": masterZohoCheckoutPageTestProfileList,
    BloodTakingOption,
    BloodDrawer,
    BloodDrawFeeCollected,
    Gender,
  },
  isOdd,
  testProfiles,
  handleChangeCheckoutPage,
  checkOutPage,
}) => {
  const testProfilesNames = useMemo(() => {
    return masterZohoCheckoutPageTestProfileList
      .map(
        (item) =>
          testProfiles.find((i) => i.Id === item.MasterTestProfile.Id)?.BmhName
      )
      .join(", ");
  }, [testProfiles, masterZohoCheckoutPageTestProfileList]);

  const handleEdit = useCallback(() => {
    handleChangeCheckoutPage(checkOutPage, "edit");
  }, [handleChangeCheckoutPage, checkOutPage]);

  const handleDelete = useCallback(() => {
    handleChangeCheckoutPage(checkOutPage, "delete");
  }, [handleChangeCheckoutPage, checkOutPage]);

  const classes = useStyle({ isOdd, BloodDrawFeeCollected });

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>{Name}</TableCell>
        <TableCell>
          {BloodTestingLab.Name} - {BloodTestingLab.Key}
        </TableCell>
        <TableCell>{testProfilesNames}</TableCell>
        <TableCell>{BloodTakingOption}</TableCell>
        <TableCell>{BloodDrawer?.Name ?? ""}</TableCell>
        <TableCell className={classes.feeCollected}>
          {BloodDrawFeeCollected ? "Yes" : "No"}
        </TableCell>
        <TableCell>{Gender ?? ""}</TableCell>
        <TableCell>
          <IconButton onClick={handleEdit}>
            <Edit fontSize="small" color="primary" />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <Delete fontSize="small" color="error" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export const PureBodyRow = memo(BodyRow);
