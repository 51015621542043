import React, { useCallback, useMemo } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import { Field, FieldArray, getIn, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import {
  getDurationString,
  getQuantityString,
  getValueFromObject,
} from "helpers";
import {
  DispenseOrderPages,
  MissingState,
  ParentOrderItem,
  TreatmentOrder,
  TreatmentOrderItem,
  useStyles,
} from "widgets/DispenseDrugs";
import { Alert } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { NumberInputField } from "components/Treatments";
import { BasicTable } from "components/BasicTable";
import { DBPharmacyProduct, ReplacementProduct } from "types";
import { ChildInjectionBundleItem } from "./ChildInjectionBundleItem";
import { ChildBacteriostaticItem } from "./ChildBacteriostaticItem";
import { HandleMissingItemsFields } from "./HandleMissingItemsFields";
import { SuggestedReplacementField } from "./HandleMissingItemsFields/SuggestedReplacement";
import {
  OrderItemDispensedUnits,
  OrderItemDispensedUnitsAlt,
} from "../OrderItemDispensedUnits";

interface MainItemProps {
  item: ParentOrderItem;
  fieldName: string;
  handleMissingItems?: boolean;
  treatmentOrder?: TreatmentOrder;
  replacements?: DBPharmacyProduct[];
  manufacturers: string[];
}

export const MainItem: React.FC<MainItemProps> = ({
  item,
  fieldName,
  treatmentOrder,
  handleMissingItems,
  replacements,
  manufacturers,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const quantityString = useMemo(() => getQuantityString(item), [item]);
  const durationString = useMemo(() => getDurationString(item), [item]);

  const replacementProducts = getIn(
    values,
    `${fieldName}.replacementProducts`
  ) as ReplacementProduct[];

  const shouldReplaceItem = useMemo(() => {
    return getIn(values, `${fieldName}.replaceItem`);
  }, [values, fieldName]);

  const checkMissingQuantity = useMemo(() => {
    return [
      DispenseOrderPages.HANDLE_MISSING_ITEMS,
      DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES,
    ].includes((values as any).currentPage);
  }, [values]);

  const missingState = useMemo(
    () => getValueFromObject(`${fieldName}.missingState`, values),
    [fieldName, values]
  );

  const shouldDisabledAddReplacementProductButton = useMemo(() => {
    if (replacementProducts.length === 0) return false;
    const lastItem = replacementProducts[replacementProducts.length - 1];
    return !lastItem?.product?.value || !lastItem?.quantity;
  }, [replacementProducts]);

  const itemPrice =
    item.Custom_Unit_Price ||
    item.Frozen_Unit_Price ||
    item.productItem.Unit_Price;

  const originalPrice = item.Quantity * itemPrice;
  const totalPrice = replacementProducts.reduce(
    (total, replacement) =>
      total +
      Number(replacement.quantity) *
        Number(replacement.product?.unitPrice || 0),
    0
  );

  const textColor =
    totalPrice > 0 && totalPrice > originalPrice ? "red" : "green";

  const renderChildItem = (child: TreatmentOrderItem, index: number) => (
    <Box key={child.id} pl={2} my={2}>
      <Box p={1} borderRadius="2px" bgcolor="#F4F4F4">
        <Typography color="primary">
          {child.productItem?.Product_Type === "Injection Bundle"
            ? "Injection Bundle"
            : "Bacteriostatic NACL Water"}
        </Typography>
        <Box mt={1} p={1} borderRadius="2px" bgcolor="#FFF">
          {child.productItem?.Product_Type === "Injection Bundle" ? (
            <ChildInjectionBundleItem
              parentItem={item}
              childItem={child}
              manufacturers={manufacturers}
              name={fieldName}
              index={index}
              handleMissingItems={handleMissingItems}
              treatmentOrder={treatmentOrder}
              replacements={replacements}
            />
          ) : (
            <ChildBacteriostaticItem
              parentItem={item}
              childItem={child}
              manufacturers={manufacturers}
              name={fieldName}
              index={index}
              handleMissingItems={handleMissingItems}
              treatmentOrder={treatmentOrder}
              replacements={replacements}
            />
          )}
        </Box>
      </Box>
    </Box>
  );

  const memoizedReplacementProducts = useCallback(
    (remove) =>
      replacementProducts.map((p, index) => {
        const columnFieldName = `${fieldName}.replacementProducts.${index}`;
        const quantityValue =
          Number(getIn(values, `${columnFieldName}.quantity`)) || 0;
        const unitPrice = p?.product?.unitPrice
          ? Number(p.product.unitPrice)
          : 0;
        const price = quantityValue * unitPrice;
        return {
          name: p.product?.value || `${index}`,
          product: (
            <Box minWidth={200} maxWidth={300}>
              <SuggestedReplacementField
                label="Product"
                required
                fieldName={`${columnFieldName}.product`}
                replacements={replacements || []}
                item={item}
              />
            </Box>
          ),
          quantity: (
            <Box minWidth={95} maxWidth={100}>
              <NumberInputField
                required
                label="Quantity"
                name={`${columnFieldName}.quantity`}
              />
            </Box>
          ),
          unitPrice: unitPrice ? (
            <Typography variant="body2" style={{ width: 75 }}>
              {unitPrice.toFixed(2)}
            </Typography>
          ) : (
            ""
          ),
          price: price ? price.toFixed(2) : null,
          action: (
            <>
              {index > 0 && (
                <IconButton aria-label="delete" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          ),
        };
      }),
    [fieldName, item, replacementProducts, replacements, values]
  );

  return (
    <>
      <Box mb={2}>
        <Grid
          container
          justify="space-between"
          style={{ background: shouldReplaceItem ? "#F4F4F4" : "inherit" }}
        >
          <Grid item xs={3}>
            <TitleValue
              title="Name"
              value={item.productItem?.Name_For_Prescription}
            />
          </Grid>
          <Grid item xs={2}>
            <TitleValue title="Quantity" value={quantityString} />
          </Grid>
          <Grid item xs={1}>
            <TitleValue title="Duration" value={durationString} />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={TextField}
              className={classes.text}
              disabled={shouldReplaceItem}
              name={`${fieldName}.usageInstructions`}
              label="Usage Instructions"
              size="small"
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={TextField}
              className={classes.text}
              name={`${fieldName}.usageNotes`}
              disabled={shouldReplaceItem}
              label="Usage Notes"
              size="small"
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
        </Grid>
        {shouldReplaceItem && (
          <Box mt={3}>
            <Typography style={{ fontWeight: "bold" }}>
              Replacement Products
            </Typography>
            <Box display="flex" mb={3} mt={1}>
              <Box width="55%" mr={2}>
                <FieldArray name={`${fieldName}.replacementProducts`}>
                  {({ push, remove }) => (
                    <>
                      <BasicTable
                        tableHeaders={[
                          { title: "Product", id: "product" },
                          { title: "Quantity", id: "quantity" },
                          { title: "Unit Price", id: "unitPrice" },
                          { title: "Price", id: "price" },
                          { title: "", id: "action" },
                        ]}
                        tableData={memoizedReplacementProducts(remove)}
                        emptyMessage="No data found"
                      />
                      <Box py={2} sx={{ display: "flex" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          disabled={shouldDisabledAddReplacementProductButton}
                          onClick={() =>
                            push({
                              id: String(Math.random()),
                              allUnitSameBatchNumber: false,
                              product: {
                                title: "",
                                value: "",
                                unitPrice: "",
                              },
                              quantity: null,
                              variants: [],
                            })
                          }
                        >
                          Add Products
                        </Button>
                        {totalPrice > 0 && (
                          <Box ml="auto">
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "16px",
                                  minWidth: 120,
                                  textAlign: "right",
                                }}
                              >
                                Total Price
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ color: textColor }}
                              >
                                £{totalPrice.toFixed(2)}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "16px",
                                  minWidth: 120,
                                  textAlign: "right",
                                }}
                              >
                                Original Price
                              </Typography>
                              <Typography variant="body2">
                                £{originalPrice.toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                </FieldArray>
              </Box>
              <Box width="45%" display="flex" flexDirection="column">
                <Box display="flex" mb={1}>
                  <Field
                    component={TextField}
                    className={classes.text}
                    name={`${fieldName}.newUsageInstructions`}
                    label="Usage Instructions"
                    size="small"
                    multiline
                    rows={5}
                    fullWidth
                    style={{ marginRight: "16px" }}
                  />
                  <Field
                    component={TextField}
                    className={classes.text}
                    name={`${fieldName}.newUsageNotes`}
                    label="Usage Notes"
                    size="small"
                    multiline
                    rows={5}
                    fullWidth
                  />
                </Box>
                <Alert severity="warning">
                  Make sure that the usage instructions and the replacement
                  products are matching.
                </Alert>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {!shouldReplaceItem && (
        <>
          {![MissingState.DISPENSE_PARTIALLY, MissingState.REPLACE].includes(
            missingState
          ) &&
            checkMissingQuantity && (
              <OrderItemDispensedUnits
                quantity={item.Quantity}
                fieldName={fieldName}
                item={item}
                manufacturers={manufacturers}
              />
            )}

          {!checkMissingQuantity && (
            <OrderItemDispensedUnits
              quantity={item.Quantity}
              fieldName={fieldName}
              item={item}
              manufacturers={manufacturers}
            />
          )}
        </>
      )}

      {shouldReplaceItem && (
        <>
          {replacementProducts
            .filter((p) => p.product?.value && p.quantity > 0)
            .map((p, index) => {
              return (
                <OrderItemDispensedUnitsAlt
                  quantity={p.quantity}
                  fieldName={`${fieldName}.replacementProducts.${index}`}
                  manufacturers={manufacturers}
                />
              );
            })}
        </>
      )}

      {handleMissingItems && (
        <HandleMissingItemsFields
          manufacturers={manufacturers}
          item={item}
          fieldName={fieldName}
          treatmentOrder={treatmentOrder}
          replacements={replacements!}
        />
      )}

      {item.children.map((child, index) => renderChildItem(child, index))}
    </>
  );
};
