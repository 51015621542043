import { TextField } from "@material-ui/core";
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import {
  getValueFromObject,
  matchReplacementItems,
  sortAlphabetically,
} from "helpers";
import React, { useCallback, useMemo } from "react";
import { AutoCompleteOption, DBPharmacyProduct, ZohoBMHDoctor } from "types";
import { TreatmentOrderItem } from "widgets/DispenseDrugs";

interface Props {
  fieldName: string;
  label: string;
  replacements: DBPharmacyProduct[];
  item: TreatmentOrderItem;
  required?: boolean;
}

export const SuggestedReplacementField = ({
  label,
  fieldName,
  replacements,
  item,
  required = false,
}: Props) => {
  const {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  const fieldTouched = useMemo(() => getValueFromObject(fieldName, touched), [
    fieldName,
    touched,
  ]);

  const fieldError = useMemo(() => getValueFromObject(fieldName, errors), [
    fieldName,
    errors,
  ]);

  const options = useMemo(
    () =>
      sortAlphabetically(
        replacements
          ?.filter((replacement) => matchReplacementItems(item, replacement))
          ?.map((replacement) => ({
            title: replacement.NameForPrescription,
            value: replacement.ZohoCrmId,
            unitPrice: replacement.UnitPrice,
          }))
      ),
    [replacements, item]
  );

  const onChange = useCallback(
    (_: any, value: ZohoBMHDoctor, reason: AutocompleteChangeReason) => {
      if (reason === "select-option" && value) {
        setFieldValue(fieldName, value);
      }
      if (reason === "clear") {
        setFieldValue(fieldName, null);
      }
    },
    [fieldName, setFieldValue]
  );

  return (
    <Field
      name={fieldName}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option?: AutoCompleteOption) => option?.title || ""}
      onChange={onChange}
      onBlur={() => {
        setFieldTouched(fieldName, true);
      }}
      getOptionSelected={(option: any, value: any) => {
        return option.value === value.value && option.title === value.title;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required={required}
          name={fieldName}
          variant="outlined"
          label={label}
          helperText={fieldTouched && fieldError}
          error={fieldTouched && !!fieldError}
          size="small"
        />
      )}
    />
  );
};
