import {
  DBPharmacyProduct,
  Owner,
  ReplacementProduct,
  ReplacementProductAlt,
  ZohoPharmacy,
  ZohoTreatment,
} from "types";

export enum DispenseOrderPages {
  PICKLIST = "picklist",
  HANDLE_MISSING_ITEMS = "handle-missing-items",
  CONFIRM_DISPENSING = "confirm-dispensing",
  MEDICINE_LABELS = "medicine-labels",
  MEDICINE_LABELS_AND_CHANGE_NOTES = "medicine-labels-and-change-notes",
}

export interface FetchPicklistResponse {
  url: string;
  base64: string;
}

export enum MedicationCategory {
  PRESCRIPTION = "Prescription",
  OVER_THE_COUNTER = "Over-The-Counter",
  UNKNOWN = "Unknown",
  NONE = "None",
}

export enum ProductType {
  MEDICATION = "Medication",
  INJECTION_BUNDLE = "Injection Bundle",
  SUPPLEMENTARY = "Supplementary",
}

interface NameAndId {
  name: string;
  id: string;
}

export interface TreatmentOrder {
  Prescription: NameAndId;
  Replacement_Reason: null;
  Owner: Owner;
  Prescription_State: string;
  Manual: boolean;
  Waive_Shipping_Charges: boolean;
  Name: string;
  Last_Activity_Time: string;
  has_Replacement_Item: boolean;
  Exchange_Rate: number;
  Province_Region_State: string;
  has_Missing_Item: boolean;
  Currency: string;
  Street: string;
  id: string;
  Delay_Reason: null;
  Pharmacy_Phone: string;
  Date_Sent_To_Pharmacy: null;
  Created_Time: string;
  Apartment_Floor_Door_Other: string;
  City: string;
  Courier_Order_Number: null;
  Original_Order: NameAndId | null;
  Recurring_Invoice: null;
  Dispensing_Date: null;
  State: string;
  Replacement_Price: null;
  Pharmacy: ZohoPharmacy;
  Country: string;
  $zia_owner_assignment: string;
  Blueprint_State: string;
  Disposal_Date: null;
  Expected_Pick_Up_Date: null;
  Courier_Manifest_Number: null;
  Credit_or_Refund_the_Order: boolean;
  Cancellation_Reason: null;
  Pharmacy_Name: string;
  Payment_Status: string;
  Migrated: boolean;
  Courier: null;
  Delivery_Change_Notes: null;
  Replacement_Created: boolean;
  Prescription_Period_in_Days: null;
  Postal_Code_Zip: string;
  Modified_Time: string;
  Replacement_Invoice: null;
  Urgent: boolean;
  Order_Received_Date: string;
  Late_Dispensing_Date: null;
  Unsubscribed_Time: null;
  Requested_Changes: null;
  Patient: NameAndId;
  Invoice_Number: string;
  Replacement: boolean;
  Doctor: NameAndId;
  Pharmacy_Address: string;
  Order_Date: string;
  Replacement_Payment: null;
  Type: string;
  Changes_Requested: boolean;
  Treatment: NameAndId;
  Locked__s: boolean;
  Pharmacy_URL: string;
  Order_Delivery_Date: null;
  Tag: any[];
  originalOrder?: TreatmentOrder;
  deliveryChangeNotes?: string;
}

interface NameAndId {
  name: string;
  id: string;
}

interface DispensedUnit {
  Expiry_Date: string;
  Batch_Number: string;
  Product_Variant: NameAndId;
  id: string;
  productVariant?: {
    Manufacturer: string;
    Product: NameAndId;
  };
}

export interface ProductItemVariant {
  GTIN: null | string;
  Barcode_Type: null | string;
  Barcode: null | string;
  Manufacturer: string;
  id: string;
  Product?: {
    id: string;
    Link: string;
    Product_Name: string;
  };
}

interface ProductItemDosingUnit {
  name: string;
  id: string;
}

export interface ProductItem {
  Qty_in_Demand: number;
  Owner: Owner;
  Sales_Start_Date: null | string;
  Product_Active: boolean;
  Name_For_Prescription: string;
  Meds_Usage_Unit_Name: string;
  Unlicensed: boolean;
  Manufacturer: null | string;
  id: string;
  Unit_Cost: null | number;
  Created_Time: string;
  Product_Name: string;
  Handler: null | string;
  Sele_Prod: null | string;
  Support_Start_Date: null | string;
  High_Quantity_Reason: null | string;
  Used_With: null | string;
  State: string;
  Pharmacy: {
    name: string;
    id: string;
  };
  Dosage_Per_Usage_Unit: number;
  Taxable: boolean;
  Product_Category: string;
  Description: string;
  Sellable_Units: null | number;
  Usage_Unit_Volume_ml: number;
  Vendor_Name: null | string;
  Allow_factor: boolean;
  Dosing_Unit: ProductItemDosingUnit;
  Enough_For: null | string;
  Extra_Syringe_Volume_ml: any[];
  Product_Code: null | string;
  Allow_multiple: boolean;
  Usage_Units_Per_Sellable_Unit: number;
  Support_Expiry_Date: null | string;
  Extra_Injection_Type: null | string;
  Modified_Time: string;
  Treatment_Area: null | string;
  Injection_Type: null | string;
  Medicine_Type: {
    name: string;
    id: string;
  };
  Med_Usage_Unit: {
    name: string;
    id: string;
  };
  Usage_Unit: null | string;
  Qty_Ordered: number;
  Dosage_Per_ml: number;
  Medication_Category: MedicationCategory;
  Locked__s: boolean;
  Qty_in_Stock: number;
  Tag: any[];
  Dosing_Type: {
    name: string;
    id: string;
  };
  Product_Type: ProductType;
  Warnings: null | string;
  Sales_End_Date: null | string;
  Syringe_Volume_ml: null | number;
  Unit_Price: number;
  Reorder_Level: number;
  variants: ProductItemVariant[];
}

export enum MissingState {
  SEND_TO_BMH = "send",
  REPLACE = "replace",
  DISPENSE_PARTIALLY = "partial-dispense",
  MARK_AS_OWED = "mark",
}

export enum Decision {
  PARTIAL_DISPENSE_SEND_TO_BMH = "Dispense Partially and Send to BMH",
  REPLACE = "Replace",
  PARTIAL_DISPENSE_MARK_REST_OWED = "Dispense Partially and Mark Rest as Owed",
  MARK_AS_OWED = "Mark as Owed",
  SEND_TO_BMH = "Send to BMH",
}

export const decisionMap: Record<Decision, MissingState> = {
  [Decision.PARTIAL_DISPENSE_SEND_TO_BMH]: MissingState.SEND_TO_BMH,
  [Decision.REPLACE]: MissingState.REPLACE,
  [Decision.PARTIAL_DISPENSE_MARK_REST_OWED]: MissingState.DISPENSE_PARTIALLY,
  [Decision.MARK_AS_OWED]: MissingState.MARK_AS_OWED,
  [Decision.SEND_TO_BMH]: MissingState.SEND_TO_BMH,
};

export interface TreatmentOrderItem {
  Owner: Owner;
  Shipping_Charges: null | number;
  Alcohol_Wipes_Quantity: null | number;
  Custom_Unit_Price: number;
  Name: string;
  Last_Activity_Time: string;
  Item: {
    name: string;
    id: string;
  };
  Replacement_Prescription_Name: null | string;
  Extra_Needles_Quantity: null | number;
  Frequency_of_Use: string;
  Unsubscribed_Mode: null | string;
  Exchange_Rate: number;
  Number_of_Use: null | number;
  Currency: string;
  Usage_Instructions: string;
  Admin_Route: NameAndId;
  id: string;
  Pharmacy_Phone: string;
  Created_Time: string;
  Needle_Filter: null | string;
  Method_of_Disposing: null | string;
  Prescription_Name: string;
  Prescription_Item: NameAndId;
  Extra_Needle: null | string;
  Sellable_Unit: null | string;
  Meds_Usage_Unit: string;
  Dispensed_Unit: DispensedUnit[];
  Extra_Needle_Filter: null | string;
  Price: null | number;
  Missing_Quantity: number;
  Selected_Product: NameAndId;
  Suggested_Replacement_Quantity: null | number;
  Duration_Unit: string;
  Orders: NameAndId;
  Pharmacy_Name: string;
  Dosing_Unit: NameAndId;
  Treatment_Medicine: NameAndId;
  Suggested_Replacement: null | NameAndId;
  Parent_Item: null | NameAndId;
  Needle_Filters_Quantity: null | number;
  Migrated: boolean;
  Usage_Note: string;
  Units_Per_Sellable_Unit: number;
  Dosing_Amount: number;
  Generation_Date: null | string;
  Include_Alcohol_Wipes: boolean;
  Modified_Time: string;
  Extra_Needle_Filters_Quantity: null | number;
  Needles_Quantity: null | number;
  Unsubscribed_Time: null | string;
  Replacement_Quantity: null | number;
  Quantity: number;
  Replacement_Usage_Notes: null | string;
  Replacement: null | NameAndId;
  Pharmacy_Address: string;
  Decision: string;
  Extra_Syringes_Quantity: null | number;
  Dispensing_Note: null | string;
  Usage_Unit_Volume: number;
  Needle: null | string;
  Duration_Period: number;
  Locked__s: boolean;
  Pharmacy_URL: string;
  Tag: any[];
  Dosing_Type: string;
  Product_Type: ProductType;
  $approval_state: string;
  Warnings: null | string;
  Syringes_Quantity: null | number;
  Frozen_Unit_Price: null | number;
  productItem: ProductItem;
  missingState?: MissingState;
  remainingQuantity?: number;
  medicineItem?: TreatmentMedicine;
  replaceItem?: boolean;
  replacementProducts?: ReplacementProduct[];
  isChild?: boolean;
  index?: number;
  usageNotes?: string;
  usageInstructions?: string;
  newUsageNotes?: string;
  newUsageInstructions?: string;
  pharmacyName?: string;
  pharmacyAddress?: string;
  pharmacyPhone?: string;
  pharmacyURL?: string;
  replacements: {
    productId: string;
    productName?: string;
    quantity: number;
    variants: {
      id?: string;
      batchNumber: string;
      expiryDate: string;
      manufacturer: string;
    }[];
  }[];
}

export interface ParentOrderItem extends TreatmentOrderItem {
  children: TreatmentOrderItem[];
  replacements: ReplacementProductAlt[];
}

interface Prescription {
  Owner: Owner;
  Blueprint_State: string;
  Prescription_Type: string;
  Usage_Notes: null;
  Explanation_for_Incorrect_Prescripton: null;
  Requester_Name: null;
  Original_Prescription: null;
  Manual: boolean;
  Number_of_Repetitions: number;
  Name: string;
  Last_Activity_Time: string;
  Pharmacy_Notes: null;
  Unsubscribed_Mode: null;
  Migrated: boolean;
  Incorrect_Prescription: boolean;
  Legacy_Prescription: null;
  id: string;
  Requester_Email: null;
  Client: NameAndId;
  $approved: boolean;
  Generation_Date: string;
  Last_Status_Change_Date: string;
  Unsubscribed_Time: null;
  Generation_Reason: string[];
  Signing_Date: string;
  Prescription_Dosing_Explanation: null;
  Doctor: NameAndId;
  External_Doctor_Name: null;
  Treatment: NameAndId;
  State: string;
  Pharmacy: NameAndId;
  Locked__s: boolean;
  Tag: any[];
  crmLink?: string;
}

export interface PrescriptionItem {
  Prescription: NameAndId;
  Time_of_day: null;
  Owner: Owner;
  Usage_Notes: null;
  Product: NameAndId;
  Name: string;
  Last_Activity_Time: string;
  Meds_Usage_Unit_Name: NameAndId;
  Frequency_of_Use: string;
  Unsubscribed_Mode: null;
  Number_of_Use: null;
  id: string;
  Time_of_day1: any[];
  Syringe_Volume1: null;
  Created_Time: string;
  Treatment_Medicines: NameAndId;
  Administration_Route: NameAndId;
  Quantity_Dispensed: number;
  Description: string;
  Dosing_Unit: NameAndId;
  Treatments: null;
  Units_Per_Sellable_Unit: number;
  Dosing_Amount: number;
  Modified_Time: string;
  Unsubscribed_Time: null;
  Quantity_To_Dispense: number;
  Name_for_Prescription: string;
  Only_Once: boolean;
  Item_Dosing: string;
  Usage_Unit_Volume: number;
  Locked__s: boolean;
  Tag: any[];
  Dosing_Type: NameAndId;
  Product_Type: ProductType;
}

interface TreatmentMedicine {
  Owner: Owner;
  Other_Administration_Route: null;
  Usage_Notes: string;
  As_Required_If_Needed: boolean;
  Dosing_Unit: NameAndId;
  Name: string;
  Last_Activity_Time: string;
  Frequency_of_Use: string;
  Unsubscribed_Mode: null;
  Number_of_Use_X: number;
  Cyclical_Use_Days: null;
  id: string;
  Dosing_Type_Name: string;
  Dosing_Amount: number;
  $approved: boolean;
  Cyclical_Stop_Days: null;
  Other_Medicine_Type: null;
  Time_of_Day: string[];
  Body_Part: null;
  Modified_Time: string;
  Treatment_Area: NameAndId;
  Other_Time_of_Day: null;
  Created_Time: string;
  Notes_for_Medicine_Selection: string;
  Unsubscribed_Time: null;
  $editable: boolean;
  $orchestration: false;
  Medicine: NameAndId;
  Specify_Custom_Dosing_Instructions: boolean;
  Treatment: NameAndId;
  Administration_Route: NameAndId;
  Temporary_Use_Days: null;
  $in_merge: false;
  Custom_Dosing_Instructions: null;
  Locked__s: boolean;
  Dosing_Type: NameAndId;
  Tag: any[];
  treatmentArea: {
    Owner: Owner;
    Other_Treatment_Area: null;
    Name: string;
    Last_Activity_Time: null;
    Unsubscribed_Mode: null;
    id: string;
    Modified_Time: string;
    Treatment_Area: NameAndId;
    Created_Time: string;
    Unsubscribed_Time: null;
    Treatment: NameAndId;
    Locked__s: boolean;
    Tag: string[];
  };
}

export interface DispenseOrderDetails {
  treatment: ZohoTreatment;
  treatmentOrder: TreatmentOrder;
  treatmentOrderItems: TreatmentOrderItem[];
  prescription: Prescription;
  prescriptionItems: PrescriptionItem[];
  treatmentMedicines: TreatmentMedicine[];
}

export interface FetchTreatmentOrdersResponse {
  data: DispenseOrderDetails;
}

export interface FetchReplacementProductsResponse {
  data: {
    list: DBPharmacyProduct[];
  };
}

export interface FetchManufacturersResponse {
  manufacturers: string[];
}

export interface OrderItemDispensedUnit {
  id: string;
  manufacturer: string;
  expiryDate: string;
  batchNumber: string;
  isDeletable: boolean;
}

export enum BarcodeType {
  GS1 = "GS1",
  OTHER = "OTHER",
}
export interface BarcodeData {
  barcodeType: BarcodeType;
  barcode: string;
  GTIN?: string;
  batchNumber?: string;
  expiryDate?: string;
  serialNumber?: string;
  quantity: number;
}
