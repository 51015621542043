import {
  Interval,
  BloodTakingOptionEnum,
  Address,
  GenderEnum,
  BloodDrawBilledStatus,
} from "@deep-consulting-solutions/bmh-constants";
import { ProductType } from "widgets/DispenseDrugs";
import { OrderTypeEnum } from "widgets/PathwaySpecifyDetailsBlueprint";

export enum ZohoModuleEnum {
  CONTACTS = "Contacts",
  LEADS = "Leads",
  PATHWAYS = "Pathways_2_0",
  LABORATORIES = "Laboratories",
  TEST_PROFILES = "Test_Profiles",
  PATHWAYS_X_TEST_PROFILES = "Pathways_X_Test_Prof",
  UNMATCHED_HL7_FILES = "Unmatched_HL7_Files",
  BLOOD_DRAWER = "Labs",
  MEETINGS = "Events",
  USERS = "users",
}

export enum ZohoUserProfileEnum {
  ADMIN = "Administrator",
  STANDARD = "Standard",
  BMH_STAFF = "BMH Staff",
  BMH_DOCTORS = "BMH Doctors",
  BMH_STAFF_WITH_BLOOD_TEST_ENTRY = "BMH Staff with Blood Test Entry",
  BMH_STAFF_WITH_BLOOD_TEST_MANAGEMENT = "BMH Staff with Blood Test Management",
  BMH_MANAGEMENT = "BMH Management",
  BMH_APPROVER = "BMH Approver",
  API_ADMIN = "API Administrator",
}

export enum BloodDrawerTypeEnum {
  LABORATORY = "Laboratory",
  PARTNER_BLOOD_DRAWER = "Partner Blood Drawer",
  PARTNER_NURSE_SERVICE = "Partner Nurse Service",
  PATIENT_SOURCED_BLOOD_DRAWER = "Patient Sourced Blood Drawer",
  LAB_MANUAL_RESULTS = "Lab (Manual Results)",
}

export enum ZohoServiceEnum {
  currentUser = "currentUser",
}

export enum SendGridStatusEnum {
  processed = "processed",
  dropped = "dropped",
  deferred = "deferred",
  bounce = "bounce",
  delivered = "delivered",
}

export interface SendGridEmailStatus {
  email: string;
  status: SendGridStatusEnum;
}

export interface ZohoButtonEntity {
  ButtonPosition: string;
  Entity: string;
  EntityId: string | string[];
}

export type ZohoEntity = ZohoButtonEntity;

export interface Layout {
  name: string;
  id: string;
}

export interface ZohoAccount {
  k: string;
}

export interface Client {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  mcm?: {
    id: string;
    name: string;
    zohoID: string;
  };
}

export interface Clients {
  [id: string]: Client;
}

export interface ObjWithId {
  id: string;
}

export interface ObjWithAny {
  [key: string]: any;
}

export interface Data extends ObjWithId, ObjWithAny {}

export interface Column<T extends Data> {
  key: keyof Omit<T, "id">;
  header: string;
  info: string;
  editable: boolean;
  required: boolean;
  isBoolean?: boolean;
  align?: "right" | "center" | "left";
  autoFocus?: boolean;
  stickyLeft?: boolean;
  type?: string;
  select?: boolean;
  options?: string[];
}

export enum RowActionsEnum {
  Edit = "Edit",
  ChangeStatus = "Change Status",
}

export enum StatusEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export interface PatchTestObxCodesReqBody {
  bmhName: string;
  labCodes: { labKey: string; code: string }[];
}

export interface ZohoClientRecord {
  id: string;
  Email: string;
  First_Name: string;
  Full_Name: string;
  Last_Name: string;
  Gender?: GenderEnum;
  Next_Check_up_Date: string;
  Next_Check_up_Type: string;
  Notification_Date_for_Next_Consult: string;
  Mobile: string;
  Phone: string;
  Shipping_City: string | null;
  Shipping_Country: string | null;
  Shipping_State: string | null;
  Shipping_Street: string | null;
  Shipping_Street_2: string | null;
  Shipping_Zip: string | null;
}

export interface ZohoBMHDoctor {
  id: string;
  Prescription_Name: string;
  First_Name: string;
  Name: string;
  Last_Name: string;
  User: {
    id: string;
  };
}

export interface ZohoMeetingRecord {
  id: string;
  Who_Id: {
    name: string;
    id: string;
  };
}

export type ZohoRecord = ZohoClientRecord | ZohoPathway | ZohoMeetingRecord;

export enum DayEnum {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export type IntervalsByDayMap = {
  [dayOfWeek: string]: Interval[];
};

export enum BloodDrawFeeCollectionEnum {
  REQUIRED = "REQUIRED",
}

export interface ZohoPathway {
  Client_Name: ZohoModule;
  BD_Category: string | null;
  Owner: CreatedBy;
  Blood_Test_Date: null;
  $currency_symbol: string;
  $photo_id: null;
  $review_process: Approval;
  $upcoming_activity: null;
  Quantity_of_Green_Tubes: string;
  Last_Status_Update_Time: null;
  Special_Notes?: null | string;
  Name: string;
  Last_Activity_Time: Date;
  Modified_By: CreatedBy;
  $review: null;
  $state: string;
  Unsubscribed_Mode: null;
  Offer_MCM_Call: boolean;
  $process_flow: boolean;
  Retest: boolean;
  Stage: string;
  Unknown_Blood_Test_Data_presents: boolean;
  BD_Provides_Own_Kits: boolean;
  id: string;
  BD_Prepaid_by_BMH: boolean;
  $approved: boolean;
  Type_of_Order: OrderTypeEnum | null;
  Quantity_of_Purple_Tubes: string;
  $approval: Approval;
  Modified_Time: Date;
  Blueprint_Status: null;
  Created_by_System: boolean;
  Created_Time: Date;
  Quantity_of_Yellow_Tubes: string;
  Unsubscribed_Time: null;
  Quantity_of_Grey_Tubes: string;
  $editable: boolean;
  Doctor_Consultation_Needed: boolean;
  Blood_Taking_Option: BloodTakingOptionEnum | null;
  $orchestration: boolean;
  $in_merge: boolean;
  $status: string;
  Blood_Drawer: ZohoModule | null;
  Tag: any[];
  Created_By: CreatedBy;
  Diagnostic_Laboratory: ZohoModule | null;
  $approval_state: string;
  Send_Order_Form_to_Blood_Drawer: boolean;
  Shipping_City?: string | null;
  Shipping_Street?: string | null;
  Shipping_Country?: string | null;
  Shipping_Apartment_Flat_Door_Other?: string | null;
  Shipping_Province?: string | null;
  Shipping_Post_Code?: string | null;
  Blood_Draw_Fee_Collected: boolean;
  Blood_Draw_Fee_Collection_Required?: string | null;
  Retest_Decision_Explanation_and_Failure_Reason?: string | null;
  Does_the_Client_Need_to_Pay_for_the_Retest: "Yes" | "No";
  Retest_Kit_Invoice_Number: null | string;
  Retest_Invoice_Number: null | string;
  Retest_Arrangements_Cancellation_Reason: null | string;
  Retest_Required: "Yes" | "No";
  Billed_Status: BloodDrawBilledStatus | null;
  Blood_Draw_Invoice_Number: string | null;
}

export interface Approval {
  delegate?: boolean;
  approve: boolean;
  reject: boolean;
  resubmit: boolean;
}

export interface ZohoModule {
  module?: string;
  name?: string;
  id: string;
}

export interface CreatedBy {
  name: string;
  id: string;
  email: string;
}

export interface RelatedList {
  display_label: string;
  visible: boolean;
  api_name: string;
  module: Module;
  name: string;
  id: string;
  href: string;
  type: string;
}

export interface Module {
  api_name: string;
  id: string;
}

export interface ZohoCUDReturn {
  code: string;
  details: {
    Modified_Time?: Date;
    Modified_By?: ZohoModule;
    Created_Time?: Date;
    id: string;
    Created_By?: ZohoModule;
  };
  message: string;
  status: string;
}

export interface Contact {
  id: string;
  lastUpdateByAppUserId: null;
  lastUpdateByZohoUserId: null;
  lastUpdateSource: string;
  createdAt: Date;
  updatedAt: Date;
  patientID: string;
  contactType: string[];
  dateOfBirth: string | null;
  phone2: null;
  emailForUpdate: null;
  isDeleted: boolean;
  zohoInvoiceCustomerID: string;
  mcmCall30MinLink: null;
  mcmCall60MinLink: null;
  user: null;
  customer: Customer;
  mcm?: {
    id: string;
    name: string;
    zohoID: string;
  } | null;
  zohoID: string;
  firstName: string | null;
  lastName: string;
  email: string;
  emailOptOut: boolean;
  oldEmails: string[];
  phone: string | null;
  dob: string | null;
  gender: GenderEnum | null;
  isEmailVerified: boolean;
  isClaimed: boolean;
  hasPendingInvite: boolean;
  timezone: string | null;
  shippingAddress: Address;
}

export interface Customer {
  id: string;
  lastUpdateByAppUserId: null;
  lastUpdateByZohoUserId: null;
  lastUpdateSource: null;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  lastLogin: null;
  dateInvited: Date;
}

export interface ZohoBloodDrawer {
  Prepaid_by_BMH: boolean;
  Owner: CreatedBy;
  Category: string;
  Email: string | null;
  $currency_symbol: string;
  $photo_id: null;
  $review_process: Approval;
  $upcoming_activity: null;
  Send_Order_Form_to_Blood_Drawer: boolean;
  $canvas_id: null;
  Name: string;
  Last_Activity_Time: Date;
  Blood_Test_Order_Form_Email_Custom_Instructions: null;
  Record_Image: null;
  Modified_By: CreatedBy;
  $review: null;
  $state: string;
  Unsubscribed_Mode: null;
  $process_flow: boolean;
  Phone: null;
  id: string;
  $approved: boolean;
  $approval: Approval;
  Modified_Time: Date;
  Created_Time: Date;
  Unsubscribed_Time: null;
  Address_Information: AddressInformation[];
  $editable: boolean;
  $orchestration: boolean;
  $in_merge: boolean;
  $status: string;
  Phone_3: null;
  Phone_2: null;
  Tag: any[];
  Created_By: CreatedBy;
  Secondary_Email: null;
  $approval_state: string;
  Provides_Own_Kits: boolean;
}

export interface AddressInformation {
  ZIP: string;
  Local_Phone: string;
  Working_Hours: string;
  Branch_Name: string;
  $in_merge: boolean;
  Parent_Id: ZohoModule;
  Street: string;
  Region: string;
  Country: string;
  id: string;
  City: string;
}

export interface ZohoCurrentUser {
  created_time: string;
  Modified_Time: string;
  role: Layout;
  image_link: string;
  profile: Layout;
  last_name: string;
  microsoft: boolean;
  created_by: Layout;
  zuid: string;
  confirm: boolean;
  Modified_By: Layout;
  locale_code: string;
  full_name: string;
  territories: any[];
  Currency: string;
  alias: null;
  id: string;
  sandboxDeveloper: boolean;
  first_name: null;
  email: string;
  Reporting_To: null;
  status: string;
}

export interface FETestObxCodeLab {
  key: string;
  code: string;
  isUpdated?: boolean;
  unit: string;
  observationAltIdentifier: string;
  normalRange: string;
}
export interface FETestObxCode {
  id: string;
  status: StatusEnum;
  isDeletable: boolean;
  bmhCodeName: string;
  hasUpdated: boolean;
  labs: {
    [labKey: string]: FETestObxCodeLab;
  };
}

export interface AutoCompleteOption {
  title: string;
  value: string;
}

export interface ZohoDiagnosisOption {
  display_value: string;
  id: string;
  reference_value: string;
}
export interface ZohoTreatment {
  id: string;
  Doctor: {
    id: string;
    name: string;
  } | null;
  Owner: {
    id: string;
    name: string;
  };
  Contact: {
    id: string;
    name: string;
  } | null;
  Diagnosis: string[];
  State: string;
  Name: string;
  url: string;
  Other_Important_Instructions: string;
  Treatment_Period_in_Months: number | null;
  Requested_Changes: string | null;
  treatmentState?: string;
  doctorName?: string;
  treatmentName: string;
  treatmentUrl: string;
  Modified_Time: string;
}

export interface ZohoTreatmentTreatmentArea {
  id: string;
  Treatment_Area: {
    id: string;
    name: string;
  };
  Other_Treatment_Area: string;
  Modified_Time: string;
}

export interface ZohoMedUsageUnit {
  id: string;
  Discard_Remaining: boolean;
}

export interface ZohoTreatmentMedicine {
  Modified_Time: string;
  id: string;
  Treatment_Area: {
    id: string;
    name: string;
  };
  Medicine: {
    id: string;
    name: string;
  };
  Other_Medicine_Type: string;
  Specify_Custom_Dosing_Instructions: boolean;
  Usage_Notes: string;
  Notes_for_Medicine_Selection: string;
  Notes_For_Medicine_Selection: string;
  Custom_Dosing_Instructions: string;
  Dosing_Type: {
    id: string;
    name: string;
  };
  Dosing_Unit: {
    id: string;
    name: string;
  };
  Dosing_Amount: number;
  Administration_Route: {
    id: string;
    name: string;
  } | null;
  Other_Administration_Route: string;
  Body_Part: string;
  Number_of_Use_X: number;
  Cyclical_Stop_Days: number;
  Cyclical_Use_Days: number;
  Temporary_Use_Days: number;
  Time_of_Day: string[];
  Frequency_of_Use: string;
  As_Required_If_Needed: boolean;
  dose?: string;
  products?: ZohoProduct[];
  injectionBundles?: ZohoProduct[];
  product: ZohoSelectedProduct | null;
}

export interface ZohoSelectedProduct {
  id: string;
  Billing_Cycle_Period_X: number;
  Billing_Cycle_Unit: string;
  Modified_Time: string;
  Early_First_Repeat?: boolean;
  Product: {
    id: string;
    name: string;
  } | null;
  Self_Supply: boolean;
  Only_Once: boolean;
  Quantity: number | null;
  Custom_Unit_Price: number;
  injectionBundle: ZohoSelectedProduct | null;
  bacteriostatic: ZohoSelectedProduct | null;
  delivered?: boolean;
  product?: ZohoProduct;
}

export interface ZohoProduct {
  id: string;
  Product_Name: string;
  Unit_Price: number;
  Enough_For: string;
  Syringe_Volume_ml: string;
  Dosage_Per_ml: string;
  Pharmacy: {
    id: string;
    name: string;
  };
  Usage_Unit_Volume_ml: string;
  Dosing_Unit: {
    id: string;
    name: string;
  };
  Description: string;
  Medication_Category: string;
  Product_Type: ProductType;
  Med_Usage_Unit: {
    id: string;
    name: string;
  } | null;
  Usage_Units_Per_Sellable_Unit: number;
}

export interface ZohoTreatmentArea {
  id: string;
  Name: string;
  medicineType: ZohoMedicineType[];
  Modified_Time: string;
}

export interface ZohoDiagnosis {
  label: string;
  selected: boolean;
  forFemale?: boolean;
  value?: string;
}

export interface ZohoMedicineType {
  id: string;
  Name: string;
  No_Dosage_Amount: boolean;
  Manual: boolean;
  Usage_Notes: string;
  Default_Usage_Note: string;
  Default_Dosing_Types: null | {
    id: string;
    name: string;
  };
  Default_Dosing_Unit: null | {
    id: string;
    name: string;
  };
  Treatment_Area: {
    name: string;
  };
}

export interface ZohoDosingUnit {
  id: string;
  Name: string;
  Dosing_Units: {
    id: string;
    name: string;
  };
}

export interface ZohoDosingType {
  id: string;
  Name: string;
  dosingTypeUnit: ZohoDosingUnit[];
}

export interface ZohoMedicineAdminRoute {
  id: string;
  Name: string;
}

export interface ZohoDosingAdminRoute {
  id: string;
  Name: string;
  Administration_Routes: {
    id: string;
    name: string;
  };
  Dosing_Types: {
    id: string;
    name: string;
  };
  Body_Part: "Optional" | "Required";
}

export interface TreatmentMedicineFormValues {
  value: string;
  title: string;
  specifyDosingInstructions: boolean;
  manual: boolean;
  usageNotes: string;
  notesForMedicineSelection: string;
  customDosingInstruction: string;
  dosingUnit: AutoCompleteOption | null;
  dosingType: AutoCompleteOption | null;
  administrationRoute: AutoCompleteOption | null;
  noDosageAmount: boolean;
  dosingAmount: number | null;
  bodyPart: string;
  frequencyOfUse: AutoCompleteOption | null;
  timeOfDay: AutoCompleteOption[] | null;
  numberOfUse: number | null;
  patternOfUse: AutoCompleteOption | null;
  numberOfDays: number | null;
  cyclicalUseDays: number | null;
  cyclicalStopDays: number | null;
  id: string;
}

export interface TreatmentTreatmentAreaFormValues {
  value: string;
  title: string;
  medicinesSelected: TreatmentMedicineFormValues[];
}

export interface TreatmentMedicinesFormValues {
  id: string;
  usageNotes: string;
  notesForMedicineSelection: string;
  product:
    | (AutoCompleteOption & {
        pharmacyZohoId: string;
      })
    | null;
  selfSupply: boolean;
  onlyOnce: boolean;
  selectedProductId: string | null;
  delivered?: boolean;
  period: number | null;
  quantity: number | null;
  customUnitPrice: number | null;
  injectionBundle: {
    product: AutoCompleteOption | null;
    selfSupply: boolean;
    customUnitPrice: number | null;
    quantity: number | null;
    selectedProductId: string | null;
  };
  bacteriostatic: {
    product: AutoCompleteOption | null;
    selectedProductId: string | null;
    selfSupply: boolean;
    customUnitPrice: number | null;
    quantity: number | null;
  };
}

export interface TreatmentFormValues {
  treatmentTreatmentAreas: TreatmentTreatmentAreaFormValues[];
  instructions: string;
  treatmentPeriod: number;
  diagnosis: ZohoDiagnosis[];
  treatmentMedicines: TreatmentMedicinesFormValues[];
  supplementaryProducts: SupplementaryProductValues[];
}

export interface Treatment {
  Id: string;
  ZohoCrmId: string;
  Name: string;
  State: string;
  "CTTPs List": CttpDocument[];
  Type: string;
}

export interface CttpDocument {
  id: string;
  Id: string;
  ZohoCrmId: string;
  State: string;
  Name: string;
  type: string;
}

export interface UserLocation {
  ip: string;
  country_name: string;
  country: string;
  latitude: string;
  longitude: string;
}

export interface SigningDetails {
  id: string;
  expiresAt: string;
  status: string;
  signingUrl: string;
  signingURLKey: string;
}

export interface SigningResponsePayload {
  message?: string;
  status?: string;
  data: {
    signingUrlResponse?: {
      data: SigningDetails;
      error: boolean;
      message: string;
    };
  };
}

export interface SigningResponsePayloadAlt {
  message?: string;
  error: boolean;
  signingUrlResponse?: {
    data: SigningDetails;
    message: string;
    status: string;
  };
}

export type SigningRequestPayload = {
  treatmentId: string;
  cttpOrPrescriptionId: string;
  type: string;
  locationInfo: UserLocation;
  userId?: string;
};

export type VenesectionSigningRequestPayload = {
  treatmentId: string;
  prescriptionId: string;
  locationInfo: UserLocation;
  userId?: string;
};

export interface Prescription {
  id: string;
  label: string;
  type: string;
  Name: string;
  isSigned?: boolean;
  Prescription_Type: string;
  State: string;
  Signing_Date: string | null;
  Pharmacy: {
    name: string;
  } | null;
}

export interface Meeting {
  StartTime?: string;
  EndTime?: string;
  timezone?: string;
}

export type GetTreatmentsResponse = {
  data: {
    list: Treatment[];
  };
};

export type GetCttpResponse = {
  data: {
    list: CttpDocument[];
  };
};

export interface ZohoTreatmentOrder {
  id: string;
  recordCrmLink: string;
  State: string;
  Payment_Status: string;
  Name: string;
  Invoice_Number: string;
  invoiceUrl: string;
  Treatment: {
    id: string;
  };
  Prescription: {
    id: string;
  };
}

export interface Owner {
  name: string;
  id: string;
  email: string;
}

interface ModifiedBy {
  name: string;
  id: string;
  email: string;
}

interface AssociatedDoctor {
  name: string;
  id: string;
}

interface LegacyTreatment {
  Activation_Date: null | string;
  Owner: Owner;
  Prescription_Type: null | string;
  Try_Other_Cards: boolean;
  Injection_Supplies: string[];
  RX_Qty_10: null | string;
  id: string;
  Diagnosis: any[];
  Date_Treatment_Paused: null | string;
  Last_Overdue_Checkup_Notification: null | string;
  Client_Overdue_for_Checkup: boolean;
  Any_NOT_Self_Supplied_Medication: boolean;
  Cancellation_Reason: null | string;
  RX_Item_10: null | string;
  Original_Treatment: null | string;
  $canvas_id: string;
  Migrated: boolean;
  Recurring_Invoice_Number: null | string;
  Reason_for_Pausing_Treatment: null | string;
  Requested_Changes: null | string;
  Repeats: string;
  Doctor: {
    name: string;
    id: string;
  };
  Type: null | string;
  Any_Self_Supplied_Injection_Supplementary1: boolean;
  Layout: Layout;
  Selected_Package: null | string;
  Contact_Client_to_Continue_Treatment_Later: boolean;
  RX_Item_81: null | string;
  Tag: any[];
  Orders_Created: boolean;
  RX_Qty_1: string;
  RX_8_Dosing: null | string;
  Prescription_Only: boolean;
  RX_7_Dosing: null | string;
  RX_9_Dosing: null | string;
  RX_6_Dosing: null | string;
  Name: string;
  Last_Activity_Time: string;
  RX_4_Dosing: null | string;
  Go_to_Finalization_Clicked: boolean;
  RX_5_Dosing: null | string;
  Matching_Pharmacies1: null | string;
  RX_3_Dosing: string;
  RX_Qty_7: null | string;
  RX_Qty_6: null | string;
  RX_Qty_9: null | string;
  RX_Qty_8: null | string;
  First_Payment_Date: null | string;
  RX_Qty_3: string;
  RX_Qty_2: string;
  RX_Qty_5: null | string;
  RX_Qty_4: null | string;
  RX_Item_9: null | string;
  RX_Item_7: null | string;
  RX_Item_5: null | string;
  RX_Item_6: null | string;
  RX_Item_3: string;
  RX_Item_4: null | string;
  Medical_Records: null | string;
  RX_1_Dosing: string;
  Venesection_Instructions: null | string;
  State: null | string;
  Rejection_Reason: null | string;
  RX_10_Dosing: null | string;
  Renewal_First_Payment_Date: null | string;
  Modified_By: ModifiedBy;
  Has_Associated_Card: boolean;
  RX_Item_1: string;
  RX_Item_2: string;
  Modified_Time: string;
  Date_Treatment_Resumed: null | string;
  RX_2_Dosing: string;
  Associated_Doctor: AssociatedDoctor;
  Date: string;
  Reason_for_Continuing_Treatment: null | string;
  Contact: any;
  Treatment_Period_in_Months: null | number;
  Any_Injection_Supplementary_Products: boolean;
  [key: string]: any;
}

export interface SignedLegacyCTTP {
  zohosign__Date_Completed: string;
  zohosign__Date_Sent: string;
  id: string;
  Name: string;
  recordCrmLink: string;
}
export interface MigrateTreatmentData {
  treatment: LegacyTreatment;
  recurringInvoices: ZohoRecurringInvoice[];
  invoices: ZohoInvoice[];
  prescriptions: any[];
  signedLegacyPrescription: any[];
  signedLegacyCTTP: SignedLegacyCTTP[];
  clientLatestMeeting: any;
}

export interface DBPharmacy {
  Id: string;
  Email: string;
  ZohoCrmLayout: unknown | null;
  AllowedCountries: string;
  PaymenttoPharmacy: string;
  ShippingCharges: string;
  CrmApiName: unknown | null;
  Phone: string | null;
  NotAllowedCountries: string;
  Address: string;
  Preference: number;
  Website: string;
  Manual: boolean;
  OrderStatusUpdateRequired: boolean;
  CountriesAllowed: unknown | null;
  Name: string;
  ZohoCrmId: string;
}

interface DBMedicineTypes {
  Id: string;
  Name: string;
  ZohoCrmId: string;
}

interface DBMedsUsageUnit {
  Id: string;
  DiscardRemaining: boolean;
}
export interface DBPharmacyProduct {
  DosagePerUsageUnit: number;
  Warnings: null | string;
  ModifiedTime: string;
  DosingUnitId: string;
  InjectionType: null | string;
  ExtraInjectionType: null | string;
  ProductName: string;
  Barcode: null | string;
  DosingTypeId: null | string;
  UsageUnitsPerSellableUnit: number;
  Unlicensed: boolean;
  ZohoCrmLayout: null | string;
  ProductType: string;
  EnoughFor: null | string;
  ProductCode: null | string;
  ZohoCrmId: string;
  MedsUsageUnitName: string;
  Description: string;
  TreatmentAreaId: null | string;
  ProductCategory: string;
  Taxable: boolean;
  NameForPrescription: string;
  MedUsageUnitId: string;
  CrmApiName: null | string;
  UnitPrice: number;
  UsageUnitVolumeml: null | string;
  ProductActive: boolean;
  Id: string;
  UsageUnit: null | string;
  BarcodeType: null | string;
  ExtraSyringeVolumeml: null | string;
  MedicineTypeId: string;
  PharmacyId: string;
  MedicineTypes: DBMedicineTypes;
  SyringeVolumeml: null | string;
  State: string;
  DosagePerml: null | string;
  GTIN: null | string;
  UnitCost: null | string;
  TreatmentAreas: null | string;
  DosingTypes: null | string;
  PharmacyName: string;
  MedsUsageUnit: DBMedsUsageUnit;
}

export interface MedicineSelected extends TreatmentMedicineFormValues {
  treatmentArea: {
    name: string;
    id: string;
  };
  medicineType?: {
    name: string;
    id: string;
  };
}

export interface ChildProduct {
  product: AutoCompleteOption;
  quantity: number | null;
  selfSupply: boolean;
  customUnitPrice: number | null;
  billingCycleUnit: string;
  period: number | null;
}

export interface MedicineProduct {
  medicineId: string;
  medicineName: string;
  product: AutoCompleteOption;
  usageNotes: string;
  quantity: number | null;
  period: number | null;
  billingCycle: string;
  onlyOnce: boolean;
  selfSupply: boolean;
  customUnitPrice: number | null;
  injectionBundle?: ChildProduct;
  bacteriostatic?: ChildProduct;
}
export interface SupplementaryProductValues {
  product:
    | (AutoCompleteOption & {
        pharmacyZohoId: string;
      })
    | null;
  billingCycleUnit: AutoCompleteOption | null;
  quantity: number | null;
  period: number | null;
  onlyOnce: boolean;
  selfSupply: boolean;
  customUnitPrice: number | null;
  keyId: string;
  id?: string;
  delivered?: boolean;
}

export interface ZohoInvoice {
  invoice_number: string;
  status: string;
  due_date: string;
  total: number;
  invoice_url: string;
  currency_symbol: string;
  id: string;
  recurrence_name?: string;
  recurrence_link?: string;
}

export interface ZohoRecurringInvoice {
  invoices?: ZohoInvoice[];
  recurrence_name: string;
  recordCrmLink: string;
}

export interface ZohoPharmacy {
  id: string;
  Name: string;
}

export type ZohoPrescription = {
  id: string;
  Patient: {
    Shipping_State: string;
    Shipping_City: string;
    Shipping_Zip: string;
    Shipping_Street: string;
    Shipping_Country: string;
  };
  PrescriptionItems: ZohoPrescriptionItem[];
  Prescription_Type: string;
};

export type ZohoPrescriptionItem = {
  id: string;
  Quantity: string;
  Usage_Notes: string;
  Quantity_To_Dispense: number;
  Quantity_Dispensed: number;
  Product: {
    name: string;
    id: string;
  };
};

export type ManualOrderRequestPayload = {
  prescriptionId: string;
  street: string;
  apartment: string | undefined;
  city: string;
  province: string;
  postCode: string;
  country: string;
  items: {
    prescriptionItemId: string;
    quantity: number;
    usageNotes: string;
  }[];
};
export interface TreatmentAreaFormValues {
  value: string;
  title: string;
  medicinesSelected: MedicineSelected[];
}
export interface MigrateTreatmentFormValues {
  doctor: string;
  diagnosis: ZohoDiagnosis[];
  treatmentTreatmentAreas: TreatmentAreaFormValues[];
  instructions: string;
  treatmentPeriod: string;
  firstPaymentDate: string;
  treatmentMedicines: MedicineProduct[];
  legacyCttp: string;
  manualOrAutoSelection: string;
  nextCheckupType: { title: string; value: string } | string;
  nextCheckupDate: string;
  invoicesWithDecision: string[];
  pharmacies: { title: string; value: string }[];
  billingCycleUnit: { title: string; value: string };
  supplementaryProducts: SupplementaryProductValues[];
}

export interface MigratedLegacyTreatment extends LegacyTreatment {
  recordCrmLink: string;
}

export interface MigrateTreatmentResponse {
  data: {
    treatment: MigratedLegacyTreatment;
  };
}

export interface FinalizeTreatmentResponse {
  data: FinalizeTreatmentData;
}

export interface FinalizeTreatmentData {
  treatmentMedicines: (ZohoTreatmentMedicine & {
    treatmentArea: ZohoTreatmentTreatmentArea;
  })[];
  contact: ZohoClientRecord;
  pharmacies: ZohoPharmacy[];
  usageUnits: ZohoMedUsageUnit[];
  productUrl: string;
  otherTreatmentMedicines: FinalizeZohoOtherTreatmentMedicine[];
  bacteriostaticProducts: ZohoProduct[];
  otherProducts: ZohoProduct[];
  supplementaryProducts: ZohoProduct[];
  treatment: ZohoTreatment;
  treatmentMedicinesForMatchingProducts: FinalizeMatchingProductMedicine[];
  treatmentMedicinesForMatchingPackages?: FinalizeMatchingPackageData;
}

export interface FinalizeMatchingProductMedicine
  extends Omit<ZohoTreatmentMedicine, "products"> {
  treatmentArea: ZohoTreatmentTreatmentArea;
  products: FinalizeMatchingProductDetails[];
}

export interface FinalizeMatchingPackageData {
  mappedMatchingMedPackages: FinalizeMatchingMedPackage[];
}

export interface FinalizeMatchingMedPackage {
  id: string;
  Name: string;
  products: FinalizeMatchingPackageProduct[];
}

export interface ZohoMatchingPackage extends ZohoSelectedProduct {
  id: string;
  Early_First_Repeat?: boolean;
  treatmentMedicine: ZohoTreatmentMedicine;
  product: ZohoProduct;
  packageMedicine: ZohoMedicineType;
}

export interface ZohoMatchingProduct {
  id: string;
}

export interface FinalizeMatchingProductDetails {
  id: string;
  dose: string;
  matchingProduct: MatchingProductZohoSelectedProduct;
  billingCycle: string;
  quantity: string;
  unitPrice: string;
  price: string;
  pharmacy: string;
  doseVolume: string;
  treatmentMedicine: ZohoTreatmentMedicine;
  injectionBundles: ZohoProduct[];
  supplementaryProducts?: {
    injectionBundle?: {
      product: string;
      quantity: number;
      selfSupply: number;
    };
    bacteriostatic?: {
      product: string;
      quantity: number;
      selfSupply: number;
    };
  };
}
export interface FinalizeMatchingPackageProduct {
  dose: string;
  doseVolume: string;
  product: {
    name: string;
    url: string;
  };
  medicineType: string;
  id: string;
  matchingMedProduct: {
    matchingMedProduct: ZohoMatchingPackage;
  };
  quantity: string;
  unitPrice: number;
  billingCycle: string;
  injectionBundles: ZohoProduct[];
  injectionBundle?: ZohoSelectedProduct;
  bacteriostatic?: ZohoSelectedProduct;
  supplementaryProducts?: {
    injectionBundle?: { product: string; quantity: number; selfSupply: number };
    bacteriostatic?: { product: string; quantity: number; selfSupply: number };
  };
}
export interface MatchingProductZohoSelectedProduct
  extends ZohoSelectedProduct {
  product: ZohoProduct;
}

export interface FinalizeZohoOtherTreatmentMedicine
  extends ZohoTreatmentMedicine {
  treatmentTreatmentArea: ZohoTreatmentTreatmentArea;
  treatmentArea: ZohoTreatmentArea;
}

export interface FinalizeWidgetValuesMatchingProductValues {
  id: string;
  selfSupply: boolean;
  customUnitPrice: number | null;
  onlyOnce: boolean;
  period: number;
  quantity: number | null;
  billingCycleUnit: AutoCompleteOption;
  injectionBundle: {
    selfSupply: boolean;
    customUnitPrice: number | null;
    product: AutoCompleteOption | null;
    quantity: number | null;
  };
  bacteriostatic: {
    selfSupply: boolean;
    customUnitPrice: number | null;
    product: AutoCompleteOption | null;
    quantity: number | null;
  };
}

export interface FinalizeWidgetValuesMatchingProductsValues {
  selectedProduct: string | null;
  usageNotes: string;
  notesForMedicineSelection: string;
  id: string;
  products: FinalizeWidgetValuesMatchingProductValues[];
}

export interface FinalizeWidgetValuesMatchingPackagesValues {
  id: string;
  usageNotes: string;
  notesForMedicineSelection: string;
  products: {
    id: string;
    selfSupply: boolean;
    customUnitPrice: number;
    usageNotes: string;
    notesForMedicineSelection: string;
    onlyOnce: boolean;
    period: number;
    injectionBundle: {
      selfSupply: boolean;
      customUnitPrice: number | null;
      product: AutoCompleteOption | null;
      quantity: number;
    };
    bacteriostatic: {
      selfSupply: boolean;
      customUnitPrice: number | null;
      product: AutoCompleteOption | null;
      quantity: number;
    };
  }[];
}

export interface FinalizeWidgetValues {
  billingCycleUnit: AutoCompleteOption;
  matchingProducts: FinalizeWidgetValuesMatchingProductsValues[];
  matchingPackages: FinalizeWidgetValuesMatchingPackagesValues[];
  selectedPackage: string;
  treatmentMedicines: TreatmentMedicinesFormValues[];
  otherTreatmentMedicines: {
    id: string;
    usageNotes: string;
    product: AutoCompleteOption;
    selfSupply: boolean;
    onlyOnce: boolean;
    period: number | null;
    quantity: number | null;
    customUnitPrice: number | null;
    notesForMedicineSelection: string;
    billingCycleUnit?: AutoCompleteOption | null;
  }[];
  supplementaryProducts: {
    usageNotes: string;
    product: AutoCompleteOption;
    selfSupply: boolean;
    onlyOnce: boolean;
    period: number | null;
    quantity: number | null;
    customUnitPrice: number | null;
    billingCycleUnit?: AutoCompleteOption | null;
  }[];
}

export interface DBTreatmentArea {
  Id: string;
  ModifiedTime: string;
  Name: string;
  ZohoCrmId: string;
  BillingPriority: number;
}

export interface TreatmentOrderItemFields {
  id: string;
  productName: string;
  allUnitSameBatchNumber?: boolean;
  isReplacement?: boolean;
  replacement?: AutoCompleteOption;
  replacementQuantity?: number;
  replacementUsageNotes?: string;
  suggestedReplacement?: AutoCompleteOption;
  children?: TreatmentOrderItemFields[];
  suggestedReplacementQuantity?: number;
  dispensingNotes?: string;
  missingQuantity?: number | null;
  quantity?: number | null;
  missingState?: string;
  usageNotes?: string;
  usageInstructions?: string;
  newUsageNotes?: string;
  newUsageInstructions?: string;
  needle?: AutoCompleteOption;
  needleFilter?: AutoCompleteOption;
  extraNeedle?: AutoCompleteOption;
  extraNeedleFilter?: AutoCompleteOption;
  includeAlcoholWipes?: boolean;
  replaceItem?: boolean;
  replacementProducts: ReplacementProduct[];
  pharmacyName?: string;
  pharmacyAddress?: string;
  pharmacyPhone?: string;
  pharmacyURL?: string;
  variants: {
    id?: string;
    batchNumber: string;
    expiryDate: string;
    manufacturer: string;
  }[];
  product?: {
    title: string;
    value: string;
    unitPrice: string;
  };
}

export interface ReplacementProduct {
  id?: string;
  isReplacement?: boolean;
  pharmacyName?: string;
  pharmacyAddress?: string;
  pharmacyPhone?: string;
  pharmacyURL?: string;
  product: {
    title: string;
    value: string;
    unitPrice: string;
  };
  allUnitSameBatchNumber?: boolean;
  quantity: number;
  variants: {
    id?: string;
    batchNumber: string;
    expiryDate: string;
    manufacturer: string;
    Product?: string;
  }[];
}

interface Variant {
  batchNumber: string;
  expiryDate: string;
  manufacturer: string;
  Product: string;
}

export interface ReplacementProductAlt {
  productId: string;
  quantity: number;
  variants: Variant[];
}
