import React, { memo, useCallback, useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  TextField as MuiTextField,
} from "@material-ui/core";
import { FastField, Field, FormikProps, getIn } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import {
  TestAbnormalFlag,
  TestFailureCode,
  GETSearchTestAbnormalFlagsRes,
} from "@deep-consulting-solutions/bmh-constants";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";

import { AutocompleteChangeReason } from "@material-ui/lab";
import {
  MapObxCode,
  MapObxCodeDetails,
} from "widgets/TestResults/TestResults.types";
import MinusBtn from "../MinusBtn";
import MultilineTextField from "./MultilineTextField";
import CustomTextField from "./CustomTextField";
import { FormValues, PathwayFormValues } from "../ResultForm.types";

type AbnormalFlagSearchResult = GETSearchTestAbnormalFlagsRes["data"][0];

const displayAbnormalFlagSearchResult = (flag?: AbnormalFlagSearchResult) => {
  if (!flag) return "";

  const { abnormalFlag, meaning } = flag;

  let text = meaning || abnormalFlag || "";
  if (meaning && abnormalFlag) text += ` (${abnormalFlag})`;
  return text;
};

const useStyle = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: s(4),
    paddingBottom: s(4),
    transition: "0.2s",

    "&:hover": {
      background: p.grey[50],
    },

    borderTop: `1px solid ${p.grey[300]}`,

    "&:first-of-type": {
      paddingTop: s(2),
      borderTop: "none",
    },

    "&:last-of-type": {
      paddingBottom: s(1),
    },
  },
  fields: {
    flex: 1,
  },
  fieldsWrapper: {
    flex: 1,
  },
  fieldsRowFirst: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  fieldsRowSecond: {
    display: "flex",
    alignItems: "center",
    marginTop: s(2),
  },
  bloodTestResultLineItemField: {
    width: `calc(1.5 * (100% / 6.5 - ${s(5) / 6.5}px))`,
  },
  field: {
    width: `calc(100% / 6.5 - ${s(5) / 6.5}px)`,
  },
  minus: {
    marginLeft: s(1),
  },
  notes: {
    flex: 1,
    marginRight: s(2),
  },
}));

interface TestRowProps {
  index: number;
  formikProps: FormikProps<FormValues | PathwayFormValues>;
  onMinusClick?: (index: number) => any;
  abnormalFlags: TestAbnormalFlag[];
  failureMap: { [failureCode: string]: TestFailureCode };
  onFailureFound: (index: number, failure: TestFailureCode) => any;
  disableIsFailed: boolean;
  disableAbnormalFlags: boolean;
  obxCodesBmhNames: MapObxCode[];
  obxUnits: string[];
  observationIDs: string[];
}

const TestRow: React.FC<TestRowProps> = ({
  index,
  formikProps,
  onMinusClick,
  abnormalFlags,
  failureMap,
  onFailureFound,
  disableIsFailed,
  disableAbnormalFlags,
  obxCodesBmhNames,
  obxUnits,
  observationIDs,
}) => {
  const [additionalOptions, setAdditionalOptions] = useState<
    MapObxCodeDetails[]
  >([]);
  const onValueChangeAdditionalCall = useCallback(
    (v: string) => {
      onFailureFound(index, failureMap[v.toLowerCase()]);
    },
    [failureMap, onFailureFound, index]
  );

  const classes = useStyle({ onMinusClick });

  const { errors, touched, setFieldValue, values } = formikProps;

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.fieldsWrapper}>
        <Box className={classes.fieldsRowFirst}>
          <Field
            required
            component={Autocomplete}
            options={obxCodesBmhNames}
            name={`results.${index}.name`}
            label="Blood Test Result line item"
            className={classes.bloodTestResultLineItemField}
            getOptionLabel={(item: MapObxCode) => item.bmhName || ""}
            onChange={(
              _: any,
              value: MapObxCode,
              reason: AutocompleteChangeReason
            ) => {
              if (reason === "select-option" && value) {
                setFieldValue(`results.${index}.name`, value);
                setAdditionalOptions(value.details);
                let details: MapObxCodeDetails | undefined;
                if (values.lab) {
                  details = value.details.find((d) => {
                    return d.labs.find((l) => {
                      return l.key === values.lab!.key;
                    });
                  });
                }

                if (!details && !values.lab) {
                  details =
                    value.details.find(
                      (d) => d.normalRange && d.observationID && d.unit
                    ) || undefined;
                }
                if (details) {
                  setFieldValue(`results.${index}.identifier`, details);
                  setFieldValue(
                    `results.${index}.normalRange`,
                    details.normalRange || ""
                  );
                  setFieldValue(`results.${index}.unit`, details);
                }
              }
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MuiTextField
                {...params}
                variant="outlined"
                label="Blood Test Result line item"
                name={`results.${index}.name`}
                helperText={(errors as any)[`results.${index}.name`]}
                error={
                  (touched as any)[`results.${index}.name`] &&
                  !!(errors as any)[`results.${index}.name`]
                }
              />
            )}
          />

          <Field
            required
            label="Observation ID"
            options={[...additionalOptions, ...observationIDs]}
            component={Autocomplete}
            className={classes.field}
            name={`results.${index}.identifier`}
            getOptionLabel={(option: string | MapObxCodeDetails) => {
              if (!option || typeof option === "string") return option || "";
              return `${option.observationID || ""}`;
            }}
            renderOption={(option: string | MapObxCodeDetails) => {
              if (!option || typeof option === "string") return option || "";
              return `${option.observationID || ""} (${option.labs
                .map((l) => l.key)
                .join(", ")})`;
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MuiTextField
                {...params}
                variant="outlined"
                label="Observation ID"
                name={`results.${index}.identifier`}
                helperText={(errors as any)[`results.${index}.identifier`]}
                error={
                  (touched as any)[`results.${index}.identifier`] &&
                  !!(errors as any)[`results.${index}.identifier`]
                }
              />
            )}
          />

          <FastField
            label="Result"
            required
            name={`results.${index}.value`}
            component={CustomTextField}
            className={classes.field}
            onChangeAdditionalCall={onValueChangeAdditionalCall}
          />

          <Field
            required
            label="Unit"
            options={[...additionalOptions, ...obxUnits]}
            component={Autocomplete}
            className={classes.field}
            name={`results.${index}.unit`}
            getOptionLabel={(option: string | MapObxCodeDetails) => {
              if (!option || typeof option === "string") return option || "";
              return `${option.unit || ""}`;
            }}
            renderOption={(option: string | MapObxCodeDetails) => {
              if (!option || typeof option === "string") return option || "";
              return `${option.unit || ""} (${option.labs
                .map((l) => l.key)
                .join(", ")})`;
            }}
            onChange={(
              _: any,
              value: string | MapObxCodeDetails,
              reason: AutocompleteChangeReason
            ) => {
              if (reason === "select-option" && value) {
                setFieldValue(`results.${index}.unit`, value);
                if (value && typeof value !== "string") {
                  setFieldValue(
                    `results.${index}.normalRange`,
                    value.normalRange || ""
                  );
                }

                if (typeof value === "string") {
                  setFieldValue(`results.${index}.normalRange`, "");
                }

                const obsID: string | MapObxCodeDetails = getIn(
                  values,
                  `results.${index}.identifier`
                );
                if (obsID && typeof obsID !== "string") {
                  setFieldValue(
                    `results.${index}.identifier`,
                    obsID.observationID
                  );
                }
              }
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MuiTextField
                {...params}
                variant="outlined"
                label="Unit"
                name={`results.${index}.unit`}
                helperText={(errors as any)[`results.${index}.unit`]}
                error={
                  (touched as any)[`results.${index}.unit`] &&
                  !!(errors as any)[`results.${index}.unit`]
                }
              />
            )}
          />

          <FastField
            required
            label="Normal Range"
            name={`results.${index}.normalRange`}
            component={TextField}
            className={classes.field}
          />

          <div className={classes.field}>
            <Field
              required
              name={`results.${index}.abnormalFlag`}
              component={Autocomplete}
              options={abnormalFlags}
              getOptionLabel={(option: TestAbnormalFlag) => {
                return displayAbnormalFlagSearchResult(option) || "";
              }}
              disabled={disableAbnormalFlags}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <MuiTextField
                  {...params}
                  name={`results.${index}.abnormalFlag`}
                  label="AbnormalFlag"
                />
              )}
            />
          </div>
        </Box>

        <Box className={classes.fieldsRowSecond}>
          <FastField
            label="Notes"
            name={`results.${index}.notes`}
            component={MultilineTextField}
            className={classes.notes}
          />

          <Field
            name={`results.${index}.isFailed`}
            component={CheckboxWithLabel}
            Label={{
              label: <Typography variant="body2">Result Failed</Typography>,
            }}
            type="checkbox"
            id={`test-results-form-failureCode-${index}`}
            color="primary"
            disabled={disableIsFailed}
          />
        </Box>
      </Box>

      <MinusBtn index={index} onClick={onMinusClick} />
    </Box>
  );
};

export default memo(TestRow);
