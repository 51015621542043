import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import {
  getAllItems,
  getMissingQuantity,
  getValueFromObject,
  validateMainItems,
} from "helpers";
import { useTreatmentWidgetLayout } from "layouts";
import React, { useCallback, useMemo } from "react";
import { DispenseOrderPages } from "widgets/DispenseDrugs";

interface Props {
  view: "fetch-error" | DispenseOrderPages;
  onSubmit: (values: any) => Promise<void>;
  updateCurrentPage: (page: DispenseOrderPages) => () => void;
  markOrderAsOwed: () => Promise<void>;
  confirmDispensingAndPrintLabels: (values: any) => Promise<void>;
  printLabels: (values: any) => Promise<void>;
  sendToBmh: (values: any) => Promise<void>;
}

const useStyles = makeStyles(({ palette: p }) => ({
  dangerBtn: {
    borderColor: p.error.main,
    color: p.error.main,
  },
  lastItem: {
    paddingRight: "0px !important",
  },
}));

export const DispenseOrderActions: React.FC<Props> = ({
  view,
  onSubmit,
  updateCurrentPage,
  markOrderAsOwed,
  confirmDispensingAndPrintLabels,
  printLabels,
  sendToBmh,
}) => {
  const classes = useStyles();
  const { values, setTouched, isValid } = useFormikContext();
  const { handleClose } = useTreatmentWidgetLayout();

  const onClickClose = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  const changePageAndTouchForm = useCallback(
    (page: DispenseOrderPages) => () => {
      updateCurrentPage(page)();
      setTouched({ mainItems: true });
    },
    [updateCurrentPage, setTouched]
  );

  const onConfirmDispensingAndPrintLabels = useCallback(() => {
    confirmDispensingAndPrintLabels(values);
  }, [values, confirmDispensingAndPrintLabels]);

  const onPrintLabels = useCallback(() => {
    printLabels(values);
  }, [values, printLabels]);

  const onMarkAsOwed = useCallback(async () => {
    await markOrderAsOwed();
  }, [markOrderAsOwed]);

  const onSendToBmh = useCallback(async () => {
    await sendToBmh(values);
  }, [sendToBmh, values]);

  const onDispenseOrder = useCallback(async () => {
    await onSubmit(values);
  }, [values, onSubmit]);

  const mainItems = useMemo(() => getValueFromObject("mainItems", values), [
    values,
  ]);
  const supplementaryItems = useMemo(
    () => getValueFromObject("supplementaryItems", values),
    [values]
  );
  const shouldSendToBMH = useMemo(
    () => getValueFromObject("shouldSendToBMH", values),
    [values]
  );
  const hasReplacementProducts = useMemo(
    () => mainItems.some((m: any) => m.replaceItem),
    [mainItems]
  );

  const isHandleMissingItemsFormValid = useMemo(() => {
    const allItems = getAllItems(mainItems, supplementaryItems);
    const replacementValidation =
      !shouldSendToBMH &&
      allItems
        .filter((j: any) => j.missingState === "replace")
        .every(
          (k: any) =>
            k.replacement && k.replacementQuantity && k.replacementUsageNotes
        );
    const otherValidation =
      !shouldSendToBMH &&
      allItems.every((i: any) => {
        const quantityToDispense =
          i.missingState === "replace"
            ? i.replacementQuantity
            : i.quantity -
              getMissingQuantity(
                i.missingQuantity,
                (values as any).currentPage
              );
        if (quantityToDispense === 0) return true;
        if (i.productType?.toLowerCase()?.includes("injection")) return true;
        if (
          i.medicationCategory?.toLowerCase()?.includes("counter") &&
          !i.missingQuantity
        )
          return true;
        if (
          (i.variants?.length < quantityToDispense &&
            !i.allUnitSameBatchNumber) ||
          (i.allUnitSameBatchNumber && !i.variants?.length)
        ) {
          return false;
        }

        return i.variants
          ?.slice(0, i.allUnitSameBatchNumber ? 1 : quantityToDispense)
          ?.every((j: any) => j.batchNumber && j.expiryDate && j.manufacturer);
      });

    return (
      (isValid && replacementValidation && otherValidation) || shouldSendToBMH
    );
  }, [mainItems, supplementaryItems, shouldSendToBMH, isValid, values]);

  const isConfirmDispensingFormValid = useMemo(() => {
    const allItems = getAllItems(mainItems, supplementaryItems);

    const formValidation = allItems.every((i: any) => {
      const quantityToDispense = i.quantity;
      if (quantityToDispense === 0) return true;
      if (i.productType?.toLowerCase()?.includes("injection")) return true;
      if (
        (i.variants?.length < quantityToDispense &&
          !i.allUnitSameBatchNumber) ||
        (i.allUnitSameBatchNumber && !i.variants?.length)
      ) {
        return false;
      }

      return i.variants
        ?.slice(0, i.allUnitSameBatchNumber ? 1 : quantityToDispense)
        ?.every((j: any) => j.batchNumber && j.expiryDate && j.manufacturer);
    });

    return isValid && formValidation;
  }, [mainItems, supplementaryItems, isValid]);

  const isConfirmDispensingFormValidAlt = validateMainItems(mainItems);

  return (
    <Box mx={2} maxWidth="100%">
      {" "}
      {["fetch-error"].includes(view) ? (
        <Button variant="outlined" onClick={onClickClose}>
          CLOSE
        </Button>
      ) : null}
      {view === DispenseOrderPages.PICKLIST ? (
        <>
          <Grid container justify="space-between" alignItems="center">
            <Grid xs={3} item>
              <Button
                className={classes.dangerBtn}
                variant="outlined"
                onClick={onClickClose}
                color="primary"
              >
                CLOSE
              </Button>
            </Grid>
            <Grid xs={9} item>
              <Box textAlign="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onMarkAsOwed}
                >
                  MARK ORDER AS OWED
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changePageAndTouchForm(
                    DispenseOrderPages.HANDLE_MISSING_ITEMS
                  )}
                >
                  Handle Missing Items
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changePageAndTouchForm(
                    DispenseOrderPages.CONFIRM_DISPENSING
                  )}
                >
                  CONFIRM DISPENSING AND PRINT LABELS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}
      {view === DispenseOrderPages.HANDLE_MISSING_ITEMS ? (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                onClick={updateCurrentPage(DispenseOrderPages.PICKLIST)}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            {shouldSendToBMH ? (
              <Grid item>
                <Button
                  disabled={!isHandleMissingItemsFormValid}
                  onClick={onSendToBmh}
                  color="primary"
                >
                  Send to BMH to Resolve Unavailability
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  disabled={!isHandleMissingItemsFormValid}
                  onClick={onConfirmDispensingAndPrintLabels}
                  color="primary"
                >
                  CONFIRM DISPENSING AND PRINT LABELS
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      ) : null}
      {view === DispenseOrderPages.CONFIRM_DISPENSING &&
      !hasReplacementProducts ? (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                onClick={updateCurrentPage(DispenseOrderPages.PICKLIST)}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!isConfirmDispensingFormValid}
                onClick={onPrintLabels}
                color="primary"
              >
                PRINT ITEMS LABELS
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
      {view === DispenseOrderPages.CONFIRM_DISPENSING &&
      hasReplacementProducts ? (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                onClick={updateCurrentPage(DispenseOrderPages.PICKLIST)}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!isConfirmDispensingFormValidAlt}
                onClick={onConfirmDispensingAndPrintLabels}
                color="primary"
              >
                CONFIRM DISPENSING AND PRINT LABELS
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
      {view === DispenseOrderPages.MEDICINE_LABELS ? (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                onClick={updateCurrentPage(DispenseOrderPages.PICKLIST)}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!isConfirmDispensingFormValid}
                onClick={onDispenseOrder}
                color="primary"
              >
                PRINT LABELS
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
      {view === DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES ? (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                onClick={updateCurrentPage(DispenseOrderPages.PICKLIST)}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!isHandleMissingItemsFormValid}
                onClick={onDispenseOrder}
                color="primary"
              >
                CONFIRM DISPENSING AND PRINT LABELS
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  );
};
