import React, { useCallback } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";

export const CustomCheckboxField = ({
  name,
  label = "",
  disabled = false,
  onChange,
}: {
  name: string;
  label?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}) => {
  const { setFieldValue, values, isSubmitting } = useFormikContext();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setFieldValue(name, checked);
      if (onChange) {
        onChange(checked);
      }
    },
    [name, setFieldValue, onChange]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={getIn(values, name)}
          onChange={handleChange}
          disabled={isSubmitting || disabled}
          color="primary"
        />
      }
      label={label}
    />
  );
};
