import { Box, Grid } from "@material-ui/core";
import React from "react";
import { DBPharmacyProduct } from "types";
import { NumberInputField } from "components/Treatments";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import { TreatmentOrderItem, useStyles } from "widgets/DispenseDrugs";
import { SuggestedReplacementField } from "./SuggestedReplacementField";

interface SuggestedReplacementProps {
  fieldName: string;
  replacements: DBPharmacyProduct[];
  item: TreatmentOrderItem;
  noLeftMargin?: boolean;
}

export const SuggestedReplacement: React.FC<SuggestedReplacementProps> = ({
  fieldName,
  replacements,
  item,
  noLeftMargin,
}) => {
  const classes = useStyles();
  return (
    <Box {...(!noLeftMargin && { ml: 4 })}>
      <Box mb={2}>
        <Alert severity="warning">
          Order will be sent to BMH to resolve medication unavailability
        </Alert>
      </Box>
      <Grid container>
        <Grid item xs={4}>
          <Box mb={2} maxWidth={500}>
            <SuggestedReplacementField
              label="Suggested Replacement"
              fieldName={`${fieldName}.suggestedReplacement`}
              replacements={replacements}
              item={item}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <NumberInputField
            required={false}
            label="Suggested Quantity"
            name={`${fieldName}.suggestedReplacementQuantity`}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            className={classes.text}
            name={`${fieldName}.dispensingNote`}
            label="Notes to BMH"
            size="small"
            multiline
            rows={2}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};
