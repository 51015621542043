import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useStyles } from "widgets/DispenseDrugs";
import { useFormikContext } from "formik";
import { getValueFromObject } from "helpers";
import { AllUnitsHaveSameBNField } from "../DispenseOrderItem/AllUnitsHaveSameBNField";

interface DispensedUnitsProps {
  fieldName: string;
  quantity: number;
}

export const DispensedUnitsAlt: React.FC<DispensedUnitsProps> = ({
  fieldName,
  quantity,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const variants = useMemo(
    () => getValueFromObject(`${fieldName}.variants`, values),
    [values, fieldName]
  );

  const allUnitSameBatchNumber = useMemo(
    () => getValueFromObject(`${fieldName}.allUnitSameBatchNumber`, values),
    [values, fieldName]
  );

  const productName = useMemo(
    () => getValueFromObject(`${fieldName}.product.title`, values),
    [values, fieldName]
  );
  const quantityString = useMemo(() => {
    if (allUnitSameBatchNumber && variants.length > 0) {
      return `(${quantity} of ${quantity})`;
    }
    return `(${variants ? variants.length : 0} of ${quantity})`;
  }, [allUnitSameBatchNumber, quantity, variants]);

  useEffect(() => {
    if (quantity && variants.length > quantity) {
      setFieldValue(`${fieldName}.variants`, variants.slice(0, quantity));
    }
  }, [fieldName, quantity, setFieldValue, variants]);

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Typography
          variant="body2"
          style={{ display: "flex", alignItems: "center" }}
          gutterBottom={!(quantity > 1 && variants.length <= 1)}
        >
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold", marginRight: "8px" }}
          >
            {productName}
          </Typography>
          Dispensed Units{" "}
          <span className={classes.subTitle}>{quantityString}</span>
        </Typography>
      </Grid>
      <Grid item>
        <AllUnitsHaveSameBNField quantity={quantity} fieldName={fieldName} />
      </Grid>
    </Grid>
  );
};
